@font-face {
  font-family: 'Montserrat300';
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat400';
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat500';
  src: url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat600';
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat700';
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat800';
  src: url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}
