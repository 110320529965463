// media queries
$extrasmall: 767px;
$small: 1023px;
$medium: 1366px;

$exceptMobile: 768px;
$exceptSmall: 1024px;
$aboveMedium: 1367px;

$tabView: 992px;

.isMobileView {
  @media (min-width: $tabView) {
    display: none !important;
  }
}

.isWiderThanMobile {
  @media (max-width: $tabView) {
    display: none !important;
  }
}

.isBetweenMobileAndTablet {
  @media (max-width: $small) and (min-width: $exceptMobile) {
    display: none !important;
  }
}

.isWiderThanTablet {
  @media (max-width: $small) {
    display: none !important;
  }
}

.isTabletAndSmaller {
  @media (min-width: $small) {
    display: none !important;
  }
}
