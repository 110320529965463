.about {
  .about-content {
    display: flex;
    flex-wrap: wrap;
  }
  .slick-dots {
    bottom: -50px;
    li button:before,
    li.slick-active button:before {
      color: transparent;
      opacity: 1;
    }
    li button:before {
      background-color: transparent;
      border: 2px solid $black;
      border-radius: 50%;
      display: inline-block;
      height: 12px;
      width: 12px;
    }
    li.slick-active button:before {
      background-color: $black;
    }
  }
}
