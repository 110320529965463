.stop-tracking-component {
  .stop-tracking-modal {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 30;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    @media (max-width: $medium) {
      width: 60%;
    }
    @media (max-width: $small) {
      width: 80%;
    }
  }
  .stop-tracking-button-wrapper {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    // width: 100%;
    z-index: 30;
    button {
      border-radius: 5px;
      width: 70vw;
    }
  }
}
