h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Montserrat800';
  color: $title;
}

p,
a {
  font-family: 'Montserrat400';
  margin-bottom: 0;
  color: $text;
  font-size: 1rem;
}

.menu-link,
a {
  font-family: 'Montserrat400';
  display: inline;
  cursor: pointer;
  color: $text;
  text-decoration: none;
  &:hover {
    color: $text;
  }
}

.fw300 {
  font-family: 'Montserrat300';
}
.fw400 {
  font-family: 'Montserrat400';
}
.fw600 {
  font-family: 'Montserrat600';
}
.fw500 {
  font-family: 'Montserrat500';
}
.fw700 {
  font-family: 'Montserrat700';
}
.fw800 {
  font-family: 'Montserrat800';
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.error-text {
  color: red;
  font-family: 'Montserrat600';
}
