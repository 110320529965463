.shipment-info-wrapper {
  .shipment-info-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    width: $tooltipDesktopWidth;
    @media (max-width: $extrasmall) {
      top: 100px;
      transform: unset;
      width: 90%;
    }
    .zoom-out-wrapper {
      background-color: $white;
      padding: 6px 18px;
      border-radius: 5px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
      display: inline-block;
      cursor: pointer;
    }
  }
}
