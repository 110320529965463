$primary: #b71c1c;
$text: #000000, 72%;
$title: #000000;
$white: #ffffff;
$black: #000000;
$border: #c8c8c8;
$footer: #1e1e1e;
$textLight: #706d6e;
$orange: #f59233;
$pickup: #008bea;
$delivery: #33c141;
$error: #ff0000;
$zoomOut: #ed4c5c;

.color-primary {
  color: $primary;
}
.color-white {
  color: $white;
}
.color-black {
  color: $black;
}
.color-orange {
  color: $orange;
}
.color-pickup {
  color: $pickup;
}
.color-delivery {
  color: $delivery;
}
.color-error {
  color: $error;
}
.color-zoom-out {
  color: $zoomOut;
}
