.about-card {
  margin-bottom: 64px;
  width: 32%;
  padding-left: 33px;
  margin-right: 2%;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  @media (max-width: $medium) {
    width: 48%;
    margin-right: 4%;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &:nth-of-type(3n) {
      margin-right: 4%;
    }
  }
  @media (max-width: $extrasmall) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 36px;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(2n) {
      margin-bottom: 0;
    }
  }
  .icon-wrapper {
    width: 64px;
    margin-bottom: 24px;
  }
  .text-wrapper {
    h2 {
      position: relative;
      font-size: 18px;
      font-family: 'Montserrat700';
      padding-bottom: 12px;
      &:after {
        position: absolute;
        content: '';
        width: 66px;
        height: 2px;
        bottom: 0;
        left: -33px;
        background-color: $primary;
        display: block;
      }
    }
    p {
      margin-top: 12px;
      font-size: 14px;
      font-family: 'Montserrat300';
    }
  }
  // min-height: 190px;
  // @media (max-width: $medium) {
  //   min-height: 230px;
  // }
  // @media (max-width: $small) {
  //   width: 90%;
  //   margin: 0 auto 36px;
  //   flex-direction: column;
  //   min-height: 320px;
  // }
  // .icon-wrapper {
  //   img {
  //     width: 60px;
  //   }
  //   @media (max-width: $small) {
  //     margin-bottom: 16px;
  //   }
  // }
  // .text-wrapper {
  //   padding-left: 24px;
  //   @media (max-width: $small) {
  //     padding-left: 0;
  //   }
  //   h2 {
  //     font-size: 18px;
  //     font-family: 'Montserrat700';
  //     margin-bottom: 8px;
  //     @media (max-width: $small) {
  //       margin-bottom: 16px;
  //     }
  //   }
  //   p {
  //     font-size: 14px;
  //     font-family: 'Montserrat300';
  //   }
  // }
}
