* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat400', sans-serif;
  position: relative;
  .scroll-disabled {
    height: 100%;
    overflow: hidden;
  }
}

input {
  font-family: 'Montserrat400', sans-serif;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.page {
  min-height: 100vh;
}

.section {
  padding: 64px 0;
}

.wrapper {
  width: 100%;
  padding: 0 6%;
  @media (min-width: $maxWrapperWidth) {
    padding: 0 $aboveMaxWidthPadding;
  }
  @media (max-width: $extrasmall) {
    padding: 0 5%;
  }
}
