.services {
  background: rgba(30, 30, 30, 0.9);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  .services-content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: $medium) {
      width: 90%;
    }
  }
  .slick-dots {
    bottom: -50px;
    li button:before,
    li.slick-active button:before {
      color: transparent;
      opacity: 1;
    }
    li button:before {
      background-color: transparent;
      border: 2px solid $white;
      border-radius: 50%;
      display: inline-block;
      height: 12px;
      width: 12px;
    }
    li.slick-active button:before {
      background-color: $white;
    }
  }
}
