.track-modal {
  width: 50%;
  min-height: 300px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 0.2s;
  text-align: center;
  z-index: 30;
  @media (max-width: $medium) {
    width: 60%;
  }
  @media (max-width: $small) {
    width: 80%;
    top: 50%;
  }
  @media (max-width: $extrasmall) {
    width: 90%;
    top: 55%;
    padding: 16px;
  }
  &.open {
    transform: translate(-50%, -50%) scale(1);
  }
  .logo {
    width: 55%;
    margin-bottom: 20px;
    @media (max-width: $extrasmall) {
      width: 70%;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-family: 'Montserrat500';
  }
  p {
    font-size: 13px;
    margin-bottom: 20px;
  }
  .error-text {
    text-align: left;
    margin: 0 auto 0;
    width: 70%;
    line-height: 24px;
    @media (max-width: $extrasmall) {
      width: 100%;
    }
  }
  .ant-form-item {
    margin-bottom: 20px;
  }
  .tracking-input {
    background-color: $white;
    width: 70%;
    padding: 24px;
    border: 0.25px solid rgba(0, 0, 0, 0.35);
    border-radius: 5px;
    &::placeholder {
      font-style: italic;
    }
    @media (max-width: $extrasmall) {
      width: 100%;
    }
  }
  .track-btn {
    padding: 12px 48px;
  }
  .shipment-not-found {
    padding-bottom: 50px;
    .btn {
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
