.service-card {
  background-color: $white;
  display: flex;
  padding: 24px;
  border-radius: 10px;
  width: 48%;
  margin-bottom: 36px;
  min-height: 190px;
  @media (max-width: $medium) {
    min-height: 230px;
  }
  @media (max-width: $small) {
    width: 90%;
    margin: 0 auto 36px;
    flex-direction: column;
    min-height: 320px;
  }
  .icon-wrapper {
    img {
      width: 60px;
    }
    @media (max-width: $small) {
      margin-bottom: 16px;
    }
  }
  .text-wrapper {
    padding-left: 24px;
    @media (max-width: $small) {
      padding-left: 0;
    }
    h2 {
      font-size: 18px;
      font-family: 'Montserrat700';
      margin-bottom: 8px;
      @media (max-width: $small) {
        margin-bottom: 16px;
      }
    }
    p {
      font-size: 14px;
      font-family: 'Montserrat300';
    }
  }
}
