.map {
  position: absolute;
  top: 0;
  left: 0;
  //width: 100vw;
  width: 100%;
  height: 100vh;
  z-index: 20;
  .marker {
    z-index: 10 !important;
    &.active {
      z-index: 11 !important;
    }
  }
}
