.logos {
  border-bottom: 4px solid $primary;
  .logos-slider {
    .slick-dots {
      bottom: 5px;
      li button:before,
      li.slick-active button:before {
        color: transparent;
        opacity: 1;
      }
      li button:before {
        background-color: transparent;
        border: 2px solid $black;
        border-radius: 50%;
        display: inline-block;
        height: 12px;
        width: 12px;
      }
      li.slick-active button:before {
        background-color: $black;
      }
    }
  }
  .logo-img {
    width: 150px;
    @media (max-width: $small) {
      width: 100px;
    }
    @media (max-width: $extrasmall) {
      width: 150px !important;
    }
  }
}
