.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt16 {
  padding-top: 16px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb20 {
  padding-bottom: 20px;
}
.p16 {
  padding: 16px;
}
