.tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  padding: 16px 16px 16px 6px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  border-left: 10px solid transparent;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: $extrasmall) {
    background-color: rgba(255, 255, 255, 0.9);
  }
  &:first-of-type {
    margin-top: 0;
  }
  .title {
    text-transform: uppercase;
    font-size: 10px;
    font-family: 'Montserrat600';
  }
  .status {
    text-transform: uppercase;
    font-size: 10px;
    font-family: 'Montserrat400';
    color: $textLight;
    .current-status {
      color: $black;
    }
  }
  .location {
    color: $textLight;
    font-size: 14px;
    text-transform: uppercase;
  }
  .name {
    color: $black;
    text-transform: uppercase;
    font-family: 'Montserrat500';
  }
  .time {
    color: $textLight;
    font-size: 11px;
    font-family: 'Montserrat400';
  }
  .coordinates {
    &:hover {
      text-decoration: underline;
    }
  }
  &.pickup {
    border-left: 10px solid $pickup;
    .title {
      color: $pickup;
    }
    &.active {
      background-color: $pickup;
      * {
        color: $white !important;
      }
    }
  }
  &.delivery {
    border-left: 10px solid $delivery;
    .title {
      color: $delivery;
    }
    &.active {
      background-color: $delivery;
      * {
        color: $white !important;
      }
    }
  }
  &.current {
    .title {
      text-transform: unset;
      font-size: 14px;
      font-family: 'Montserrat300';
    }
    .data {
      font-size: 14px;
      font-family: 'Montserrat700';
    }
    &.active {
      background-color: $orange;
      * {
        color: $white !important;
      }
    }
  }
  &.status-tooltip {
    background-color: $orange;
    * {
      color: $white !important;
    }
    padding: 8px 16px 8px 4px;
    width: unset;
    display: inline-block;
    cursor: unset;
  }
}
