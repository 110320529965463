.btn {
  display: inline-block;
  outline: none;
  padding: 12px 24px;
  border-radius: 36px;
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid $primary;
  &.default {
    background-color: $primary;
    color: $white;
  }
  &.light {
    background-color: $white;
    color: $primary;
  }
  &.transparent {
    padding: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-color: transparent;
    color: $primary;
  }
  &.shipment-popup {
    border: none;
    color: $white;
    border-radius: 3px;
    padding: 6px 24px;
  }
  &.pickup {
    background-color: $pickup;
  }
  &.delivery {
    background-color: $delivery;
  }
  &.transit {
    background-color: $orange;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.pointer {
  cursor: pointer;
}
