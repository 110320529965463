.header {
  z-index: 900;
  position: fixed;
  top: 30px;
  width: 1440px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white;
  border: 0.5px solid $border;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px 48px;
  border-bottom-right-radius: 10px;
  transition: border-bottom-right-radius 0.2s;
  @media (max-width: $maxWrapperWidth) {
    width: 88%;
  }
  @media (max-width: $extrasmall) {
    top: 16px;
    width: 90%;
    padding: 12px 18px;
  }
  &.mobile-menu-open {
    border-bottom-right-radius: 0px;
  }
  .logo-wrapper {
    width: 40px;
    cursor: pointer;
  }
  .links-wrapper {
    display: flex;
  }
  .menu-link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    color: $black;
    .active-dot {
      position: absolute;
      bottom: -8px;
      text-align: center;
    }
    &.track-btn {
      margin-left: 10px;
      color: #ffffff;
    }
  }
  .hamburger {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    width: 30px;
    height: 19px;
    cursor: pointer;
    .line {
      width: 25px;
      height: 3px;
      background-color: $black;
      transition: all 0.2s;
    }
    &.mobile-menu-open {
      .line:nth-of-type(1) {
        margin-top: 7px;
        transform: rotate(45deg);
      }
      .line:nth-of-type(2) {
        width: 0;
      }
      .line:nth-of-type(3) {
        margin-bottom: 11px;
        transform: rotate(-45deg);
      }
    }
  }
  .mobile-menu {
    //width: 200px;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 63px;
    right: -1px;
    background-color: $white;
    transition: all 0.5s;
    border: none;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    &.open {
      max-height: 500px;
      transition: max-height 0.2s ease-in;
      border: 0.5px solid $border;
      border-top: none;
    }
    .navlink-wrapper {
      padding: 16px 32px;
    }
    .mobile-menu-link {
      color: $black;
    }
    .track-btn {
      margin-left: 0;
      padding: 10px 0;
      font-size: 15px;
    }
  }
}
