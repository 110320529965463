.to-top {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width: $small) {
    flex-direction: column;
  }
  @media (max-width: $extrasmall) {
    position: relative;
    margin-top: 32px;
  }
  img {
    width: 30px;
  }
  .text {
    margin-left: 5px;
    color: $white;
    @media (max-width: $small) {
      margin-left: 0;
      margin-top: 5px;
    }
  }
}
