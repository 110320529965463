.platform-entry {
  position: relative;
  width: 80%;
  margin: 0 auto;
  bottom: -50px;
  z-index: 10;
  background-color: $white;
  display: flex;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  @media (max-width: $medium) {
    width: 90%;
  }
  @media (max-width: $small) {
    flex-direction: column-reverse;
    border-radius: 10px;
    overflow: hidden;
  }
  @media (max-width: $extrasmall) {
    bottom: -80px;
    width: 100%;
  }
  .text-wrapper {
    width: 55%;
    padding: 36px 72px 36px 36px;
    @media (max-width: $medium) {
      padding: 24px;
    }
    @media (max-width: $small) {
      width: 100%;
      padding: 16px;
    }
    h2 {
      font-size: 30px;
      font-family: 'Montserrat700';
      margin-bottom: 16px;
      @media (max-width: $small) {
        font-size: 24px;
      }
    }
    p {
      margin-bottom: 16px;
      @media (max-width: $small) {
        margin-bottom: 32px;
      }
    }
  }
  .button-wrapper {
    display: flex;
    @media (min-width: $exceptSmall) {
      min-height: 48px;
    }
    a {
      font-size: 14px;
      padding: 12px 18px;
    }
  }
  .img-wrapper {
    width: 45%;
    background-color: $pickup;
    @media (max-width: $small) {
      width: 100%;
      height: 225px;
    }
  }
}
