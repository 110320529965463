.hero {
  position: relative;
  min-height: 100vh;
  .scroll-down-arrow {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 20;
  }
}
