.footer {
  position: relative;
  background-color: $footer;
  padding: 100px 0 50px;
  @media (max-width: $extrasmall) {
    padding: 100px 0 20px;
  }
  .footer-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media (max-width: $small) {
      flex-direction: column;
    }
  }
  .info-box {
    p,
    a {
      color: $white;
    }
    width: 20%;
    @media (max-width: $small) {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    @media (max-width: $small) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    img {
      width: 100%;
      @media (max-width: $small) {
        width: 80%;
        margin: 0 auto;
      }
    }
    .info-box-text-wrapper {
      @media (max-width: $small) {
        display: flex;
        justify-content: space-between;
      }
    }
    .info-box-text {
      @media (max-width: $small) {
        width: unset;
        max-width: 48%;
      }
    }
    p {
      font-family: 'Montserrat300';
      color: $white;
      @media (max-width: $extrasmall) {
        font-size: 13px;
      }
    }
  }
  .general-box {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: $small) {
      width: 100%;
      margin-top: 48px;
    }
    p,
    a {
      color: $white;
    }
  }
  .email-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: $small) {
      justify-content: space-between;
    }
    p,
    a {
      color: $white;
    }
  }
  .email-box {
    width: 23.5%;
    margin-right: 2%;
    margin-bottom: 32px;
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    @media (max-width: $medium) {
      width: 32%;
      margin-right: 2%;
      &:nth-of-type(4n) {
        margin-right: 2%;
      }
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    @media (max-width: $small) {
      width: 100%;
      margin-right: 0;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
    @media (max-width: $extrasmall) {
      width: 100%;
      margin-right: 0;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    a {
      font-size: 13px;
    }
    .title {
      font-family: 'Montserrat700';
      margin-bottom: 8px;
    }
    .text {
      font-family: 'Montserrat300';
    }
  }
}
